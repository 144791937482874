/* RESET */
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 62.5%;
  scrollbar-color: grey #303030;
}

h1 {
  margin: 0;
}
@media (max-width: 650px) {
  h1 {
    font-size: 1.3em;
  }
}

a {
  text-decoration: none;
}

a,
.feeds-list-toggle,
.folder__button {
  touch-action: manipulation;
}

iframe {
  width: auto;
}

img,
audio,
canvas,
iframe,
progress,
video {
  max-width: 100%;
  height: auto;
}
.leedvibes-article-content img,
.leedvibes-article-content audio,
.leedvibes-article-content canvas,
.leedvibes-article-content iframe,
.leedvibes-article-content progress,
.leedvibes-article-content video {
  display: block;
  margin: 0 auto;
}

pre {
  white-space: pre-wrap;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

:fullscreen {
  background-color: white;
  overflow-y: scroll;
}

/* GENERAL STYLE */
body {
  display: flex;
  flex-wrap: wrap;
  width: 99vw;
  font-size: 1.3rem;
  background-color: #424242;
}
@media (max-width: 650px) {
  body {
    display: block;
    width: 100vw;
  }
}

button {
  background-color: transparent;
  border: none;
}

.error {
  background-color: #c53d3d;
}

.wrapper {
  padding-bottom: 80px;
  flex: 1 1 0;
  min-width: 0;
  min-height: 100vh;
  background-color: #FFF;
}
@media (max-width: 650px) {
  .wrapper {
    margin-bottom: 0;
    padding-bottom: 140px;
  }
}

.selected a {
  color: #FFF;
  font-weight: bold;
}

.notifications {
  margin: 0;
  color: #FFF;
  background-color: #424242;
}

.notifications__text {
  display: flex;
  padding: 1em 0.5em;
  color: #DDD;
  justify-content: space-between;
  align-items: center;
}

.article-favorite,
.article__read-unread, .mark-as-read, .folder__button {
  border: 1px dashed transparent;
}
.article-favorite:focus,
.article__read-unread:focus, .mark-as-read:focus, .folder__button:focus {
  border-color: #DDD;
}

.folder__item a, .sidebar-item-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* FEED */
.event {
  position: relative;
  padding: 0 0.5em;
  border-bottom: 0.1em solid #D9D9D9;
}
.event:not(.event--focus):hover, .event--focused:not(.event--focus) {
  background-color: #DDD;
}
.event a {
  color: #424242;
}

.event--new-last {
  border-bottom-color: #424242;
  border-width: 4px;
}

/* SIDEBAR */
.sidebar {
  flex: 0 0 270px;
  font-size: 0.9em;
  overflow: hidden;
}
.sidebar a {
  color: #fff;
}
.sidebar .login-form {
  padding: 20%;
}

.folder__item,
.feed__item {
  display: flex;
  position: relative;
  padding: 0.6rem 0.4em;
  border-left: 5px solid transparent;
}
.folder__item,
.folder__item a,
.feed__item,
.feed__item a {
  color: #fff;
}
.folder__item:hover, .folder__item:active,
.feed__item:hover,
.feed__item:active {
  background-color: #303030;
}
.folder__item.selected,
.feed__item.selected {
  border-color: white;
}

.sidebar-item {
  flex-grow: 1;
  height: 16px; /* Favicon's height */
  line-height: 16px;
  overflow: hidden;
}

@media (max-width: 650px) {
  .folder__button,
  .sidebar-item {
    padding: 12px 0;
  }
}

.go-top {
  display: inline-block;
  padding: 2px 20px;
  text-align: center;
  background-color: #686868;
  border-radius: 20%;
}

.feed-add {
  display: none;
  padding: 10px 5px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  z-index: 100;
}
.feed-add[data-is-opened=true] {
  display: block;
}

.feed-add-title {
  margin: 0 0 1em;
  color: #fff;
}

.feed-add-url,
.feed-add-folders {
  width: 80%;
}

.feed-add-url {
  padding: 0.5em;
  border: none;
}

.feed-add-folders {
  margin: 1em 0;
  padding: 0.5em 2em;
}

.sidebar > section {
  margin: 2em auto 3em;
  padding: 0 1em;
}
.sidebar > section li {
  display: flex;
  color: #fff;
  justify-content: space-between;
}

.sidebar > section button,
.button,
.leedvibes-article-meta button,
.leedvibes-article-meta .button {
  padding: 1em 2em;
  color: #fff;
  background-color: #484848;
}
.sidebar > section button:hover, .sidebar > section button:active, .sidebar > section button:focus,
.button:hover,
.button:active,
.button:focus,
.leedvibes-article-meta button:hover,
.leedvibes-article-meta button:active,
.leedvibes-article-meta button:focus,
.leedvibes-article-meta .button:hover,
.leedvibes-article-meta .button:active,
.leedvibes-article-meta .button:focus {
  color: #484848;
  background-color: #DDD;
}

.feeds-list-toggle {
  position: relative;
  overflow: hidden;
}

.feeds-list-toggle::before {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 35%;
  top: -20px;
  content: "";
  background-image: url("../images/close.svg");
  transition: transform 0.3s;
}

.is-opened.feeds-list-toggle::before {
  transform: translate3d(0, 180%, 0);
}

.feeds-list-toggle__label {
  position: absolute;
  padding: 0.5em 1.5em;
  top: 0;
  left: 0;
  transition: transform 0.3s;
}
.is-opened .feeds-list-toggle__label {
  transform: translate3d(0, 150%, 0);
}

@media (max-width: 650px) {
  .mobile-toggle,
  .feed-add {
    position: fixed;
    background-color: #424242;
    transition: all 130ms ease-in;
    will-change: transform;
    overflow: auto;
    z-index: 1000;
  }
}

@media (max-width: 650px) {
  .mobile-toggle {
    width: 100%;
    max-height: calc(100% - 60px);
  }
}
@media (max-width: 650px) {
  .mobile-toggle[data-is-opened=false] {
    transform: translate3d(0, -100%, 0);
  }
}

.unreads-badge {
  color: white;
  background: #555;
  position: absolute;
  border-radius: 50%;
  padding: 2px;
  z-index: 100;
  right: 5px;
  bottom: 0;
}

.feeds-list {
  display: block;
  margin-top: 0;
  padding: 0;
}
.feeds-list:target {
  display: block;
}

.folder {
  border-bottom: 0.2em solid #484848;
}
.folder:first-child {
  border-top: 0.2em solid #484848;
}

.folder__item a {
  margin: 0 0.3em;
}

.folder__button {
  float: left;
  margin-right: 5px;
  padding: 0;
  width: 10%;
}

.folder-closed {
  transform: rotate(270deg);
}

.feed {
  padding: 4px 0;
  background-color: #4b4b4b;
}

html:not(.no-js) .feed--closed {
  display: none;
}

.feed__item img {
  float: left;
  margin-left: 1.4em;
  margin-right: 0.3em;
  vertical-align: middle;
}
@media (max-width: 650px) {
  .feed__item img {
    margin-right: 0.8em;
  }
}

.mark-as-read {
  padding: 0 0.4rem;
  color: white;
  font-size: 0.8em;
}
@media (max-width: 650px) {
  .mark-as-read {
    padding: 12px;
  }
}

/* ARTICLE */
.article__header {
  position: relative;
  padding: 0.5em 0;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.event--focus .article__header {
  padding-bottom: 0.6em;
}
@media (max-width: 650px) {
  .article__header {
    display: grid;
    grid-template-columns: 40px auto 80px;
    grid-template-areas: "favicon site date" "favorite title mark";
    align-items: center;
    white-space: inherit;
    min-height: 4em;
  }
}

.article__feed-title {
  display: inline-block;
  width: 10%;
  color: #555;
  font-size: 0.9em;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  vertical-align: middle;
}
@media (max-width: 650px) {
  .article__feed-title {
    width: auto;
    grid-area: site;
  }
}

@media (min-width: 650px) {
  .ui-side-button {
    display: none;
  }
}

.ui-side-reverse .ui-side-button {
  transform: scaleX(-1);
}

@media (max-width: 650px) {
  .ui-side-reverse .article__header {
    grid-template-areas: "favicon site date" "mark title favorite";
  }
}

.feed-icon {
  margin-right: 0.3em;
  vertical-align: middle;
  justify-self: center;
  grid-area: favicon;
}
@media (max-width: 650px) {
  .feed-icon {
    margin: 0;
  }
}

.article__title {
  display: inline;
  font-size: 1em;
}
@media (max-width: 650px) {
  .article__title {
    display: inline-block;
    margin-left: 14%;
    grid-area: title;
  }
}
[class~=event--read] .article__title a {
  color: #BBB;
}

@media (max-width: 650px) {
  .article__feed-title,
  .feed-icon ~ .article__title {
    margin-left: 0.5em;
  }
}

.article__description {
  display: inline-block;
  margin-left: 0.4em;
  color: #888;
}
[class~=event--read] .article__description {
  color: #BBB;
}
@media (max-width: 650px) {
  .article__description {
    display: none;
  }
}
.event--focus .article__description {
  opacity: 0;
}
.article__description::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 16em;
  background-image: linear-gradient(to left, white 48%, rgba(255, 255, 255, 0) 100%);
}
.event--focused .article__description::after, .event:hover .article__description::after {
  background-image: linear-gradient(to left, #DDD 6em, rgba(221, 221, 221, 0) 100%);
}
@media (max-width: 650px) {
  .event--focused .article__description::after, .event:hover .article__description::after {
    background: none;
  }
}
@media (max-width: 650px) {
  .article__description::after {
    background: none;
  }
}

.article__date {
  position: absolute;
  right: 65px;
  color: #424242;
  font-size: 0.9em;
}
@media (max-width: 650px) {
  .article__date {
    position: relative;
    right: 0;
    grid-area: date;
    justify-self: right;
  }
}

.article-favorite {
  right: 30px;
  opacity: 0.3;
}
.article-favorite--favorited {
  opacity: 1;
}
@media (max-width: 650px) {
  .article-favorite {
    right: 0;
    grid-area: favorite;
    justify-self: left;
  }
}

.article__read-unread {
  width: 1.7em;
  right: 0;
  color: white;
  background-color: transparent;
  opacity: 0.3;
  cursor: pointer;
}
@media (max-width: 650px) {
  .article__read-unread {
    grid-area: mark;
    justify-self: right;
  }
}

.article-favorite,
.article__read-unread {
  position: absolute;
  display: flex;
  padding: 0;
  width: 30px;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
}
.article-favorite:hover, .article-favorite:active, .article-favorite:focus,
.article__read-unread:hover,
.article__read-unread:active,
.article__read-unread:focus {
  border-color: #424242;
}
@media (max-width: 650px) {
  .article-favorite,
  .article__read-unread {
    position: relative;
    padding: 0;
    width: 100%;
    min-height: 50px;
  }
}

.article__read-read {
  opacity: 1;
}

.leedvibes-article-content {
  display: none;
  margin: 0 auto 1em;
  width: 42.8125em;
  font-size: 1.6rem;
  line-height: 1.7;
  overflow: auto;
  overflow-wrap: break-word;
}
@media (max-width: 650px) {
  .leedvibes-article-content {
    padding: 0 1rem;
    width: auto;
  }
}
.leedvibes-article-content a {
  text-decoration: underline;
}

.leedvibes-article-content--is-opened {
  display: block;
}

.leedvibes-article-content__infos {
  margin: 1em 0;
}
.leedvibes-article-content__infos a {
  font-size: 2.8rem;
  line-height: 1;
}

.leedvibes-article-content-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.leedvibes-article-meta button,
.leedvibes-article-meta .button {
  margin-left: 5px;
  padding: 5px 10px;
}

/* USER SETTINGS */
.user-login,
.settings {
  display: flex;
  justify-content: space-around;
  padding: 0 1em;
  color: #CACACA;
  align-items: center;
  text-align: center;
}

.user-login {
  margin: 1em auto;
  min-width: 250px;
  flex-direction: column;
}

.settings {
  position: relative;
  padding: 13px 10px;
  min-height: 34px;
  background-color: #303030;
  z-index: 1000;
}
@media (max-width: 650px) {
  .settings {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
  }
}

.settings-item {
  padding: 0.5em 1.5em;
  min-width: 15px;
  min-height: 18px;
  border-bottom: 5px solid transparent;
  box-sizing: content-box;
  transition: border 0.2s ease-in-out 0s;
}
.settings-item:hover, .settings-item:active, .settings-item:focus {
  border-color: white;
}

button.settings-item {
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  background-color: transparent;
}
button.settings-item:focus {
  outline: dotted 1px;
}

.login-form {
  width: 100%;
}

.login-title {
  margin-bottom: 20px;
  font-size: 2rem;
}
@media (max-width: 650px) {
  .login-title {
    margin-bottom: 10px;
  }
}

.login-logo {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100px;
  filter: invert(100%);
}
@media (max-width: 650px) {
  .login-logo {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 50px;
  }
}

.login-input {
  width: 60%;
}

.login-input,
.login-submit {
  display: block;
  margin: 0.4em auto 10px;
  padding: 0.5em;
  color: #303030;
}

.login-submit {
  margin-top: 40px;
}

.login-remember-me {
  display: block;
  margin: 20px 0;
}

.extra-settings {
  display: flex;
  justify-content: center;
}

.favorite-inverted {
  fill: white;
}

.animation-spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.shortcuts-toggle {
  display: none;
}

@media (min-width: 650px) {
  html:not(.no-js) .shortcuts-toggle {
    display: block;
  }
}
.popin {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  padding: 20px;
  width: 100%;
  height: 100vh;
  color: white;
  background-color: #303030e6;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: auto;
  box-sizing: border-box;
  z-index: 1000;
}

.shortcuts-list {
  margin: 0;
  padding: 0;
}

.shortcut {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.shortcut-key {
  display: inline-block;
  margin-right: 20px;
  padding: 5px 5px 10px;
  min-width: 20px;
  color: #777;
  font-size: 12px;
  background-color: #DDD;
  border: 2px solid #F9F7F7;
  border-bottom: 4px solid #898989;
  border-right: 4px solid #898989;
  border-radius: 3px;
  box-shadow: 0 0 10px #111010;
}
.shortcut-key + .shortcut-key {
  margin-left: 20px;
}

.brightness-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.brightness-item {
  margin-bottom: 20px;
}
.brightness-item:last-of-type {
  margin-bottom: 0;
}

.brightness-button {
  display: flex;
  color: inherit;
  align-items: center;
}

.brightness-img {
  margin-right: 10px;
  width: 30px;
}

.undo-mark-as-read {
  position: fixed;
  padding: 10px;
  right: 10px;
  bottom: 10px;
  background-color: #424242;
  border-radius: 50%;
  z-index: 100;
}
.undo-mark-as-read:hover, .undo-mark-as-read:active, .undo-mark-as-read:focus {
  background-color: #333;
}
@media (max-width: 650px) {
  .undo-mark-as-read {
    padding: 20px;
    bottom: 70px;
  }
}

/* MEDIA QUERIES */
@media (max-width: 650px) {
  td {
    display: block;
  }
}
.mobile-only {
  display: none;
}
@media (max-width: 650px) {
  .mobile-only {
    display: inline;
  }
}

/* IE9- */
.no-flexboxlegacy body {
  display: block;
}

.no-flexboxlegacy .wrapper {
  margin-left: 20%;
}

.no-flexboxlegacy .sidebar {
  float: left;
  width: 20%;
}

/* IE7 */
.no-flexboxlegacy.no-boxsizing .sidebar {
  width: 19%;
}

.hidden {
  display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.dark-theme :fullscreen,
.dark-theme .wrapper {
  background-color: #202020;
}
.dark-theme .article__header .article__feed-title {
  color: #aaa;
}
.dark-theme .event {
  border-color: #262626;
}
.dark-theme .event a,
.dark-theme .article__date {
  color: #bdbdbd;
}
.dark-theme .article__title a {
  color: white;
}
.dark-theme [class~=event--read] .article__title a {
  color: #bdbdbd;
}
.dark-theme .article__description::after {
  background-image: linear-gradient(to left, #202020 48%, rgba(0, 0, 0, 0) 100%);
}
.dark-theme .article-favorite,
.dark-theme .article__read-unread {
  filter: invert(100%);
}
.dark-theme .leedvibes-article-content {
  color: white;
}
.dark-theme .event:not(.event--focus):hover,
.dark-theme .event--focused:not(.event--focus) {
  background-color: #333;
}
.dark-theme .event--focused .article__description::after,
.dark-theme .event:hover .article__description::after {
  background-image: linear-gradient(to left, #333 6em, rgba(51, 51, 51, 0) 100%);
}

.leedvibes-article-content--rss:fullscreen :not(:has(figure, img)),
.leedvibes-article-content--rss:fullscreen img {
  max-width: 700px;
  margin: 0 auto;
}

.leedvibes-article-content--rss:fullscreen .leedvibes-article-content__infos {
  margin-bottom: 50px;
  text-align: center;
}

.leedvibes-article-content--rss:fullscreen .article-fullscreen-button {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.leedvibes-article-content--rss:fullscreen img {
  width: auto;
  margin: 0 auto;
  max-width: initial;
  max-width: 90vw;
  max-height: 90vh;
}